/*
 Global variables defined in webpack DefinePlugin
 Why declared and exported this way? See: https://github.com/TypeStrong/ts-loader/issues/37#issuecomment-381375624
 */
declare const HOST: string
declare const BACKEND_ENDPOINT: string
declare const BEEDIRECT_HOST: string

const _HOST = HOST
const _BACKEND_ENDPOINT = BACKEND_ENDPOINT
const _BEEDIRECT_HOST = BEEDIRECT_HOST

export { _HOST as HOST }
export { _BACKEND_ENDPOINT as BACKEND_ENDPOINT }
export { _BEEDIRECT_HOST as BEEDIRECT_HOST }
