import { HostMessage } from '../types/iframe'
import { BEEDIRECT_HOST } from '../webpackConstants'

export function createModalIframe(queryString: string) {
    const modalIframe = document.createElement('iframe') as HTMLIFrameElement
    modalIframe.id = 'widget-modal'
    modalIframe.src = `${BEEDIRECT_HOST}/assetklasse/?${queryString}`
    modalIframe.allowFullscreen = true
    modalIframe.frameBorder = '0'
    modalIframe.setAttribute('scrolling', 'no')
    modalIframe.style.cssText =
        'position: fixed; top: 0; min-width: 100%; height: 100%; overflow: hidden; z-index: 2147483647;'

    return modalIframe
}

export function sendHostMessage(message: HostMessage) {
    parent.postMessage(message, '*')
}

export function removeModalIframe() {
    const iframe = document.getElementById('widget-modal')
    if (!iframe) {
        return
    }

    iframe.remove()
}

export function handleWidgetMessages(e: MessageEvent) {
    const data = e.data as HostMessage
    switch (data.event) {
        case 'lease:open':
            document.documentElement.appendChild(
                createModalIframe(data.value.queryString)
            )
            break
        case 'lease:close':
            removeModalIframe()
            break
        default:
            return
    }
}
