import { HOST } from './webpackConstants'
import {
    buildQueryStringFromDataAttributes,
    getDimensionValue,
} from './lib/attributes'
import { handleWidgetMessages } from './lib/beedirect-modal'
;(function () {
    function createMainIframe(targetElement: Element, index: number) {
        const width = getDimensionValue(
            targetElement.getAttribute('data-width'),
            180
        )
        const height = getDimensionValue(
            targetElement.getAttribute('data-height'),
            40
        )

        if (width === null || height === null) {
            throw new Error(
                'Beequip widget not rendered: invalid configured values for width or height'
            )
        }

        const iframe = document.createElement('iframe')
        iframe.src = `${HOST}/button/index.html?referrer=${encodeURIComponent(
            window.location.href
        )}&${buildQueryStringFromDataAttributes(targetElement.attributes)}`
        iframe.id = `beequip-button-${index + 1}`
        iframe.allowFullscreen = true
        iframe.setAttribute('scrolling', 'no')
        iframe.frameBorder = '0'
        iframe.style.cssText = `position: static; visibility: visible; display: inline-block; width: ${width}; height: ${height}; padding: 0; border: medium none; max-width: 100%; margin-top: 0; margin-bottom: 0;`

        window.addEventListener('message', handleWidgetMessages, false)

        return iframe
    }

    const allWidgets = document.querySelectorAll('.beequip-button')
    for (let i = 0; i < allWidgets.length; i++) {
        const targetElement = allWidgets[i]
        const iframe = createMainIframe(targetElement, i)

        if (targetElement.parentNode === null) {
            continue
        }

        targetElement.parentNode.replaceChild(iframe, targetElement)
    }
})()
