import { endsWith } from './string'

export function buildQueryStringFromDataAttributes(
    attributes: NamedNodeMap
): string {
    return Array.prototype.slice
        .call(attributes)
        .filter(function (attr) {
            return attr.name.indexOf('data-') !== -1
        })
        .map(function (attr) {
            return `${encodeURIComponent(
                attr.name.replace('data-', '')
            )}=${encodeURIComponent(attr.value)}`
        })
        .join('&')
}

export function getDimensionValue(
    configValue: string | null,
    defaultValue: number
): string | null {
    if (configValue === null) {
        return `${defaultValue}px`
    }

    if (/^\d+$/.test(configValue)) {
        return `${configValue}px`
    }

    if (endsWith(configValue, '%') || endsWith(configValue, 'px')) {
        return configValue
    }

    return null
}

export function mapWidgetAttributesToBeedirect(
    params: ButtonUrlParams
): BeedirectParams {
    const mapping = new Map<string, string>([
        ['token', 'intermediaryToken'],
        ['brand', 'objectBrand'],
        ['model', 'objectType'],
        ['year', 'objectYear'],
        ['category-id', 'categoryGroupId'],
        ['subcategory-id', 'categoryId'],
        ['purchase-price', 'purchasePrice'],
        ['object-used', 'equipmentUsed'],
    ])

    return Object.entries(params).reduce((attrs, [key, value]) => {
        const mappedKey = mapping.get(key)
        return mappedKey !== undefined
            ? { ...attrs, [mappedKey]: value }
            : { ...attrs, [key]: value }
    }, {})
}
